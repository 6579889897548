import { ReactComponent as ProcessSVG } from "../../icons/process.svg";

import { ReactComponent as ProcessMobileSVG } from "../../icons/process_mobile.svg";

export const Process = () => {
  return (
    <div className="flex flex-col items-center pt-14">
      <div className="flex flex-col items-center">
        <div className="text-2xl md:text-4xl font-black mb-10">Our Process</div>
        <div className="hidden xl:block">
          <ProcessSVG />
        </div>
        <div className="xl:hidden">
          <ProcessMobileSVG />
        </div>
      </div>
    </div>
  );
};
