import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDe1P0xgNTS80sFSvubC_K3zJ2oi0gczVM",
  authDomain: "archerx-web-b17e2.firebaseapp.com",
  projectId: "archerx-web-b17e2",
  storageBucket: "archerx-web-b17e2.appspot.com",
  messagingSenderId: "334616558765",
  appId: "1:334616558765:web:1ade6e36a1d3548c925a24",
  measurementId: "G-1LMW0L7J5C",
};

const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase);
