import hero from "../icons/hero.png";

export const Hero = ({ contactRef }: { contactRef: any }) => {
  return (
    <div className="flex xl:flex-row flex-col-reverse items-center justify-between">
      <div className="flex flex-col items-center xl:items-start text-center gap-2 mb-16">
        <div className="text-3xl md:text-5xl font-black -mt-8 md:-mt-16 xl:mt-0 z-10">
          Elevate Your Business
        </div>
        <div className="md:text-2xl text-white font-light">
          with Our Expert Consulting Services
        </div>
        <button
          onClick={() => {
            if (contactRef && contactRef.current)
              contactRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
          }}
          className="text-base md:text-2xl text-orange border-2 border-orange font-bold rounded-full px-5 py-3 w-36 md:w-44 mt-3"
          style={{
            background:
              "linear-gradient(180.98deg, #111111 1.03%, #FF9640 367.47%),linear-gradient(0deg, #FF9640, #FF9640)",
          }}
        >
          Contact Us
        </button>
      </div>
      <img className="rounded-3xl" src={hero} alt="Logo" />
    </div>
  );
};
