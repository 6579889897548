import { addDoc, collection } from "firebase/firestore";
import { FormEvent, useState } from "react";

import { firestore } from "../../firestore";
import { ThankYou } from "./ThankYou";

const inputStyle =
  "text-white rounded-2xl p-3 focus:border-orange border-[1px] border-solid outline-none bg-darkgrey";

export const ContactUs = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const submit = async () => {
    try {
      if (!name || !email || !phone || !desc) {
        setShowError(true);
        return;
      }

      await addDoc(collection(firestore, "forms"), {
        name,
        email,
        phone,
        desc,
      });

      setName("");
      setEmail("");
      setPhone("");
      setDesc("");
      setSubmitted(true);
    } catch (error) {
      console.error("Failed to submit form!");
    }
  };

  return (
    <div className="flex flex-col items-center pt-32 md:pb-32 pb-16 w-[274px] md:w-[550px] mx-auto">
      <div className="flex flex-col items-center w-full gap-4">
        <div className="text-2xl md:text-4xl font-black">Let's Talk!</div>
        {submitted ? (
          <ThankYou />
        ) : (
          <>
            <form className="flex flex-col gap-4 w-full text-black">
              <input
                value={name}
                autoComplete="name"
                name="fname"
                onChange={(event) => {
                  setName(event.target.value);
                  setShowError(false);
                }}
                placeholder="Full Name"
                required
                className={inputStyle}
              />
              <input
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setShowError(false);
                }}
                type="email"
                autoComplete="email"
                name="email"
                placeholder="Email Address"
                required
                className={inputStyle}
              />
              <input
                value={phone}
                type="tel"
                autoComplete="tel"
                name="phone"
                onChange={(event) => {
                  setPhone(event.target.value);
                  setShowError(false);
                }}
                placeholder="Phone Number"
                required
                className={inputStyle}
              />
              <textarea
                value={desc}
                onChange={(event) => {
                  setDesc(event.target.value);
                  setShowError(false);
                }}
                placeholder="Tell us what you need!"
                required
                className={`h-40 resize-none ${inputStyle}`}
              />
              {showError && (
                <div className="text-red-800 text-center">
                  Please fill out the from before submitting!
                </div>
              )}
              <button
                onClick={(e: FormEvent) => {
                  e.preventDefault();
                  submit();
                }}
                className="text-xl text-orange border-2 border-orange font-bold w-44 self-center rounded-full px-7- py-4"
                style={{
                  background:
                    "linear-gradient(180.98deg, #111111 1.03%, #FF9640 367.47%),linear-gradient(0deg, #FF9640, #FF9640)",
                }}
              >
                Send
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
