import { ReactComponent as Check } from "../../icons/check.svg";

export const ThankYou = () => {
  return (
    <div className="flex flex-col items-center gap-5">
      <Check className="h-[200px] text-green-500" />
      <div className="text-3xl">Thank You!</div>
      <div className="text-lg">The form was submitted successfully.</div>
    </div>
  );
};
