import about from "../icons/about.png";

export const About = () => {
  return (
    <div className="flex flex-col items-center pt-16 mx-auto">
      <div className="flex flex-col items-center">
        <div className="flex flex-col xl:flex-row gap-10">
          <img
            className="rounded-3xl sm:w-[300px] md:w-fit mx-auto"
            src={about}
            alt="Logo"
          />
          <div className="text-lg text-white -mt-28 md:-mt-56 xl:mt-0 z-10">
            <div className="text-2xl md:text-4xl font-black mb-2 text-white">
              About
            </div>

            <p className="text-sm md:text-base">
              ArcherX was founded to provide hands on consulting services to
              various types of technological companies. We help companies jump
              start their operations and bridge the gap between their goals and
              the end product.
            </p>

            <br />

            <p className="text-sm md:text-base">
              We offer assistance for medium to large companies that want to
              expand their operations. By incorporating existing resources and
              working in close collaboration with your business leadership we
              can turn your visions into reality!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
