export const ServiceCard = ({
  title,
  desc,
  Icon,
}: {
  title: string;
  desc: string;
  Icon: any;
}) => {
  return (
    <div className="flex flex-col gap-2 shadow-lg border-white border-2 rounded-3xl p-6 md:p-6 md:h-[213px]">
      <div className="flex items-center gap-3">
        <Icon />
        <div className="font-bold text-white text-xl md:text-2xl">{title}</div>
      </div>
      <hr className="border-grey" />
      <div className="font-normal text-white">{desc}</div>
    </div>
  );
};
