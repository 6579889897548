import { ReactComponent as LinkedIn } from "../icons/linkedIn.svg";
import { ReactComponent as Mail } from "../icons/mail.svg";
import { openNewTab } from "../utils/common";
import { archerXMail } from "../utils/constants";

export const Footer = () => {
  return (
    <footer>
      <hr className="border-grey" />

      <div className="flex flex-col items-center mt-4 mb-8 gap-5">
        <div className="flex flex-col md:flex-row items-center gap-5 md:gap-20 text-orange text-lg font-extralight md:text-2xl">
          <div className="flex items-center gap-3">
            <a href={`mailto:${archerXMail}`}>
              <Mail className="md:w-10 h-7 w-7" />
            </a>
            {archerXMail}
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() =>
              openNewTab("https://www.linkedin.com/company/100701358")
            }
          >
            <LinkedIn className="md:h-6 md:w-6 h-5 w-5 mb-[5px] md:mb-0" />
            ArcherX
          </div>
        </div>

        <div className="text-white font-extralight text-center">
          All Rights Reserved © ArcherX LTD
          <br />
          Design by Irit Achiel
        </div>
      </div>
    </footer>
  );
};
