import { ReactComponent as Arch } from "../../icons/arch.svg";
import { ReactComponent as Data } from "../../icons/data.svg";
import { ReactComponent as DevOps } from "../../icons/devops.svg";
import { ReactComponent as Plan } from "../../icons/plan.svg";
import { ReactComponent as Scale } from "../../icons/scale.svg";
import { ReactComponent as Tech } from "../../icons/tech.svg";
import { ServiceCard } from "./ServiceCard";

const services = [
  {
    title: "Architecture Consulting",
    desc: `We at ArcherX will make sure to aid you in structuring your service
          in a way that enables long term development and support!`,
    icon: Arch,
  },
  {
    title: "High Scalability",
    desc: `Scalability can be a challenging thing to achieve!
          We make it our top priority to make your service scalable
          and ready to deal with vast amounts of users and data!`,
    icon: Scale,
  },
  {
    title: "Planning & Design",
    desc: `Planning & Design is our speciallty! we will work with you to make your vision a reallity!
           big or small if it's something you need we can make it happen!`,
    icon: Plan,
  },
  {
    title: "Tech Stack",
    desc: `We will listen to your technical needs and choose the most appropriate tech stack,
          using the most cutting edge technologies!`,
    icon: Tech,
  },
  {
    title: "Data Integrity",
    desc: `The data of your organization will be an integral part of our development process.
          we will insure its consistency, validity, accuracy, and completeness!`,
    icon: Data,
  },
  {
    title: "DevOps",
    desc: `We will help your organization deliver applications and services at high velocity
           which will help your product to evolve and improve at a faster pace!`,
    icon: DevOps,
  },
];

export const Services = () => {
  return (
    <div className="flex flex-col items-center md:mt-[100px]">
      <div className="text-2xl md:text-4xl font-black">Our Services</div>
      <div className="text-center mb-6 mt-1 text-lg md:text-2xl text-white">
        We offer consulting in a wide range of subjects!
      </div>
      <div className="grid xl:grid-cols-3 md:grid-cols-2 items-center gap-7">
        {services.map((service, i) => {
          return (
            <ServiceCard
              key={i}
              title={service.title}
              desc={service.desc}
              Icon={service.icon}
            />
          );
        })}
      </div>
    </div>
  );
};
