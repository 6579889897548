import { ReactComponent as ArcherX } from "../icons/archerx.svg";
import { ReactComponent as LinkedIn } from "../icons/linkedIn.svg";
import { ReactComponent as Mail } from "../icons/mail.svg";
import { openNewTab } from "../utils/common";
import { archerXMail } from "../utils/constants";

export const Header = ({
  serviceRef,
  processRef,
  aboutRef,
  contactRef,
}: {
  serviceRef: any;
  processRef: any;
  aboutRef: any;
  contactRef: any;
}) => {
  const executeScroll = (ref: any) => {
    if (ref && ref.current)
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  return (
    <header className="fixed flex justify-between text-2xl z-50 w-full h-[74px] bg-black xl:px-16">
      <div className="flex items-center justify-between cursor-pointer xl:mx-0 mx-5 w-full xl:w-fit">
        <div className="flex items-center gap-3">
          <ArcherX
            className="w-16"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
          ArcherX
        </div>
        <div className="xl:hidden flex items-center gap-5 text-orange text-lg font-extralight xl:text-3xl">
          <a href={`mailto:${archerXMail}`}>
            <Mail className="h-8 w-8 mt-[6px]" />
          </a>
          <LinkedIn
            onClick={() =>
              openNewTab("https://www.linkedin.com/company/100701358")
            }
            className="h-8 w-8"
          />
        </div>
      </div>
      <div className="hidden xl:flex gap-10">
        <button onClick={() => executeScroll(serviceRef)}>Services</button>
        <button onClick={() => executeScroll(processRef)}>Our Process</button>
        <button onClick={() => executeScroll(aboutRef)}>About Us</button>
        <button onClick={() => executeScroll(contactRef)}>Contact Us</button>
      </div>
    </header>
  );
};
