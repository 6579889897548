import { useRef } from "react";
import { About } from "./components/About";
import { ContactUs } from "./components/ContactUs";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Process } from "./components/Process";
import { Services } from "./components/Services";

export const App = () => {
  const serviceRef = useRef<any>(null);
  const processRef = useRef<any>(null);
  const aboutRef = useRef<any>(null);
  const contactRef = useRef<any>(null);

  return (
    <div className="flex flex-col h-screen">
      <Header
        serviceRef={serviceRef}
        processRef={processRef}
        aboutRef={aboutRef}
        contactRef={contactRef}
      />

      <main>
        <div className="mt-20 md:mt-32 w-[274px] md:w-[705px] xl:w-[1180px] mx-auto">
          <Hero contactRef={contactRef} />

          <div ref={serviceRef} className="scroll-mt-24">
            <Services />
          </div>

          <div ref={aboutRef} className="scroll-mt-5">
            <About />
          </div>

          <div ref={processRef} className="scroll-mt-5">
            <Process />
          </div>
        </div>
        <div
          ref={contactRef}
          className="scroll-mt-5 bg-[url('./icons/bottom.png')] bg-no-repeat bg-cover bg-bottom"
        >
          <ContactUs />
        </div>
      </main>
      <Footer />
    </div>
  );
};
